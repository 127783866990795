import { lazy } from "@loadable/component";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import DynamicLoader from "./components/loaders/DynamicLoader";
import reducer, { initialState } from "./context/reducer";
import { StateProvider } from "./context/StateProvider";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { mobile_screen_width } from "./utils/common";
const App = lazy(() => import("./App"));

const envType = process.env.REACT_APP_ENV;

//tracing UI errors using Sentry
if (envType === "PROD" || envType === "BETA") {
  Sentry.init({
    dsn: "https://1c3287b278164f6c91fa52cf36cff692@o1173407.ingest.sentry.io/6268431",
    integrations: [new BrowserTracing()],
    release: "1.0",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

(function (d) {
  let s = d.createElement("script");
  /* uncomment the following line to override default position*/
  s.setAttribute("data-position", 5);
  /* uncomment the following line to override default size (values: small, large)*/
  /* s.setAttribute("data-size", "large");*/
  /* uncomment the following line to override default language (e.g., fr, de, es, he, nl, etc.)*/
  /* s.setAttribute("data-language", "null");*/
  /* uncomment the following line to override color set via widget (e.g., #053f67)*/
  s.setAttribute("data-color", "#1ab394");
  /* uncomment the following line to override type set via widget (1=person, 2=chair, 3=eye, 4=text)*/
  /* s.setAttribute("data-type", "1");*/
  /* s.setAttribute("data-statement_text:", "Our Accessibility Statement");*/
  /* s.setAttribute("data-statement_url", "http://www.example.com/accessibility";*/
  /* uncomment the following line to override support on mobile devices*/
  s.setAttribute("data-mobile", false);
  /* uncomment the following line to set custom trigger action for accessibility menu*/
  /* s.setAttribute("data-trigger", "triggerId")*/
  s.setAttribute("data-account", "1k1MFatPk1");
  s.setAttribute("src", "https://cdn.userway.org/widget.js");
  if (
    window.screen.width < mobile_screen_width ||
    window.location.hostname.includes("getdirectroom")
  ) {
    return;
  }
  (d.body || d.head).appendChild(s);
})(document);

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Suspense fallback={<DynamicLoader />}>
        <App />
      </Suspense>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
